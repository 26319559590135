
export default function TermoPlanos(){

 const conditions = "CONFIRMO esta etapa de contratação dos serviços da contratada NETSIM PROVEDOR DE SISTEMA DE INTEGRAÇÃO A MIDIA - LTDA de CNPJ 18.156.287/0001-09 e tenho total ciência do contrato de fidelidade de 12 meses deixando a taxa de adesão de R$ 500,00 isenta mediante a fidelidade.(01)Estou ciente que a empresa tem um prazo para instalação de até 48 horas.(02)Meu CPF poderá passar por uma análise antes da aprovação do contrato. (03)Todos os dados fornecidos estão seguros e são de inteira responsabilidade da Netsim Telecom.(04)No prazo de até 24 horas esta liberado o acesso aos aplicativos escolhidos com longin o usuario:(Email) e Senha:(CPF)."

    return (
       <text>
       {conditions}
       </text> 
    )
}

